<template>
  <div class="user-info">
      <h1>Administradora</h1>
      <h3>Usuaria administradora</h3>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .user-info{
        width:100%;
        text-align:right;
        padding:12px 14px;
        background-color: #fff;

        & h1 {
            font-size:16px;
            font-weight: bold;
            margin:0;
        }

        & h3 {
            font-size:14px;
            margin:0;
        }
    }
</style>